import React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Layout from "../components/Layout";
import BreadCrumps from "../ui/breadCrumps/BreadCrumps";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function ClubPage({ data }) {
  const filterCoaches = (array, filterValue) => {
    let filteredArray = array.filter(
      ({ node: coach }) => coach.staffMember == filterValue
    );

    let arrayData = filteredArray ? filteredArray : [];
    return arrayData;
  };

  const isstaffMemberList = filterCoaches(data.coaches.edges, true);
  const isNotstaffMemberList = filterCoaches(data.coaches.edges, false);

  return (
    <>
      <HelmetDatoCms seo={data.club.seo} favicon={data.site.favicon} />
      <Layout>
        <BreadCrumps title={data.club.title} />
        <section className="single-section">
          <div>
            <h1 style={{ marginBottom: `18px` }}>{data.club.title}</h1>
            <strong>AДРЕСА : {data.club.adress}</strong>
          </div>
        </section>
        {/* <section className="single-section" id="gallery">
                <div>
                        <h2 style={{textTransform: `uppercase`}}>Галерея</h2>
                        <br/>
                        { data.club.clubgallery.map((card, index)=>{
                                return (
                                    <div key={index} style={{maxWidth: `300px`, margin: `20px`}}>
                                        <GatsbyImage image={getImage(card.gatsbyImageData)} alt={"image"} />
                                    </div>
                                )
                            })
                        }
                </div>
            </section> */}
        <section className="single-section" id="gallery">
          <div>
            <h2 style={{ textTransform: `uppercase` }}>
              {data.club.sceduletitle}
            </h2>

            {data.club.sceduletitle.sceduledescription ? (
              <p>{data.club.sceduletitle.sceduledescription}</p>
            ) : null}
            <br />
            {data.club.sceduleimage ? (
              <GatsbyImage
                layout="constrained"
                image={getImage(data.club.sceduleimage.gatsbyImageData)}
                alt={"Групові заняття"}
              />
            ) : null}
          </div>
        </section>
        <section
          style={{ background: `white` }}
          className="single-section"
          id="coachers"
        >
          <div>
            <h2>ТРЕНЕРИ</h2>

            {data.cachersText?.owncoachers ? (
              <div
                className="site__border"
                key={`t-body`}
                dangerouslySetInnerHTML={{
                  __html: data.cachersText.owncoachers,
                }}
              ></div>
            ) : null}

<br/>

           

            <div className="coachers-gallery">
              {isstaffMemberList?.map(({ node: coach }, index) => {
                return (
                  <div key={index} className="coach-card">
                    <div className="coach-card-wrapper">
                      {coach.coverimage && (
                        <GatsbyImage
                          layout="constrained"
                          image={getImage(coach.coverimage.gatsbyImageData)}
                          alt={coach.title + "image"}
                        />
                      )}
                      <h3>{coach.title}</h3>
                      <br />
                      <div
                        key={`body`}
                        dangerouslySetInnerHTML={{ __html: coach.details }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>

            <br/>
            {data.cachersText?.notowncoachers ? (
              <div
                key={`t-body`}
                className="site__border"
                dangerouslySetInnerHTML={{
                  __html: data.cachersText.notowncoachers,
                }}
              ></div>
            ) : null}
            <br/>

            <div className="coachers-gallery">
              {isNotstaffMemberList?.map(({ node: coach }, index) => {
                return (
                  <div key={index} className="coach-card">
                    <div className="coach-card-wrapper">
                      {coach.coverimage && (
                        <GatsbyImage
                          layout="constrained"
                          image={getImage(coach.coverimage.gatsbyImageData)}
                          alt={coach.title + "image"}
                        />
                      )}
                      <h3>{coach.title}</h3>
                      <br />
                      <div
                        key={`body`}
                        dangerouslySetInnerHTML={{ __html: coach.details }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>

           
          </div>
        </section>
      </Layout>
    </>
  );
}

export const query = graphql`
  query ClubBySlug($slug: String) {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    cachersText: datoCmsCoachestext {
      owncoachers
      notowncoachers
    }
    club: datoCmsClub(slug: { eq: $slug }) {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      adress
      sceduletitle
      sceduledescription
      sceduleimage {
        gatsbyImageData
      }
    }
    coaches: allDatoCmsCoach(
      filter: { club: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          title
          details
          staffMember
          coverimage {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
